/*
 *   This content is licensed according to the W3C Software License at
 *   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 *
 *   Supplemental JS for the disclosure menu keyboard behavior
 */
class DisclosureNav {
  constructor(domNode) {
    this.rootNode = domNode;
    this.triggerNodes = [];
    this.controlledNodes = [];
    this.openIndex = null;
    this.useArrowKeys = true;
  }
  init() {
    let buttons = this.rootNode.querySelectorAll(
      "button[aria-expanded][aria-controls]"
    );

    for (let button of buttons) {
      let menu = button.parentNode.querySelector(".disclosure-nav__list, ul");
      if (menu) {
        // save ref to button and controlled menu
        this.triggerNodes.push(button);
        this.controlledNodes.push(menu);

        // collapse menus
        button.setAttribute("aria-expanded", "false");
        this.toggleMenu(menu, false);

        // attach event listeners
        menu.addEventListener("keydown", this.handleMenuKeyDown.bind(this));
        button.addEventListener("click", this.handleButtonClick.bind(this));
        button.addEventListener("keydown", this.handleButtonKeyDown.bind(this));
      }
    }

    this.rootNode.addEventListener("focusout", this.handleBlur.bind(this));
  }
  toggleMenu(domNode, show) {
    if (domNode) {
      domNode.style.display = show ? "block" : "none";
      if (show) {
        domNode.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }
    }
  }
  toggleExpand(index, expanded) {
    // close open menu, if applicable
    if (this.openIndex !== index) {
      this.toggleExpand(this.openIndex, false);
    }

    // handle menu at called index
    if (this.triggerNodes[index]) {
      this.openIndex = expanded ? index : null;
      this.triggerNodes[index].setAttribute("aria-expanded", expanded);
      this.toggleMenu(this.controlledNodes[index], expanded);
    }
  }
  controlFocusByKey(keyboardEvent, nodeList, currentIndex) {
    switch (keyboardEvent.key) {
      case "ArrowUp":
      case "ArrowLeft":
        keyboardEvent.preventDefault();
        if (currentIndex > -1) {
          let prevIndex =
            currentIndex <= 0 ? nodeList.length - 1 : currentIndex - 1;
          nodeList[prevIndex].focus();
        }
        break;
      case "ArrowDown":
      case "ArrowRight":
        keyboardEvent.preventDefault();
        if (currentIndex > -1) {
          let nextIndex =
            currentIndex >= nodeList.length - 1 ? 0 : currentIndex + 1;
          nodeList[nextIndex].focus();
        }
        break;
      case "Home":
        keyboardEvent.preventDefault();
        nodeList[0].focus();
        break;
      case "End":
        keyboardEvent.preventDefault();
        nodeList[nodeList.length - 1].focus();
        break;
    }
  }
  handleBlur(event) {
    let menuContainsFocus = this.rootNode.contains(event.relatedTarget);
    if (!menuContainsFocus && this.openIndex !== null) {
      this.toggleExpand(this.openIndex, false);
    }
  }
  handleButtonKeyDown(event) {
    let targetButtonIndex = this.triggerNodes.indexOf(document.activeElement);

    // close on escape
    if (event.key === "Escape") {
      this.toggleExpand(this.openIndex, false);
    }

    // move focus into the related menu and opens it if necessary
    else if (this.useArrowKeys && event.key === "ArrowDown") {
      event.preventDefault();
      if (this.openIndex !== targetButtonIndex) {
        this.toggleExpand(targetButtonIndex, true);
      }
      this.controlledNodes[this.openIndex].querySelector("a").focus();
    }

    // handle arrow key navigation between top-level buttons, if set
    else if (this.useArrowKeys) {
      this.controlFocusByKey(event, this.triggerNodes, targetButtonIndex);
    }
  }
  handleButtonClick(event) {
    let button = event.target;
    let buttonIndex = this.triggerNodes.indexOf(button);
    let buttonExpanded = button.getAttribute("aria-expanded") === "true";
    this.toggleExpand(buttonIndex, !buttonExpanded);
  }
  handleMenuKeyDown(event) {
    if (this.openIndex === null) {
      return;
    }

    let menuLinks = Array.prototype.slice.call(
      this.controlledNodes[this.openIndex].querySelectorAll("a")
    );
    let currentIndex = menuLinks.indexOf(document.activeElement);

    // close on escape
    if (event.key === "Escape") {
      this.triggerNodes[this.openIndex].focus();
      this.toggleExpand(this.openIndex, false);
    }

    // handle arrow key navigation within menu links, if set
    else if (this.useArrowKeys) {
      this.controlFocusByKey(event, menuLinks, currentIndex);
    }
  }
  // switch on/off arrow key navigation
  updateKeyControls(useArrowKeys) {
    this.useArrowKeys = useArrowKeys;
  }
}

/* Initialize Disclosure Menus */
window.addEventListener(
  "load",
  function (event) {
    let menus = document.querySelectorAll("[data-disclosure-nav]");
    let disclosureMenus = [];

    for (let i = 0; i < menus.length; i++) {
      disclosureMenus[i] = new DisclosureNav(menus[i]);
      disclosureMenus[i].init();
    }
  },
  false
);
